<template>
  <b-overlay
    :show="data === null || disabledCache"
    rounded="sm"
  >
    <b-form
      v-if="data"
    >
      <b-row>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-group
            :label="$t('form.maintenance_mode.label')"
            label-for="maintenance_mode"
            :state="errors && errors.maintenance_mode ? false : null"
          >
            <v-select
              id="maintenance_mode"
              v-model="data.maintenance_mode"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="maintenance_mode"
            />
            <b-form-invalid-feedback v-if="errors && errors.maintenance_mode">
              {{ errors.maintenance_mode[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('admin.settings.cache')"
            label-for="cache"
          >
            <b-button
              v-if="$ability.can('update', 'settings')"
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              :disabled="disabledCache"
              @click="modalShowCache = true"
            >
              CLEAR
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.settings.currency')"
            label-for="currency"
          >
            <v-select
              v-model="data.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="currency"
            />
            <b-form-invalid-feedback v-if="errors && errors.currency">
              {{ errors.currency[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('admin.settings.other_currency')"
            label-for="admin_currency"
          >
            <v-select
              v-model="data.admin_currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencies"
              :reduce="val => val.value"
              :clearable="false"
              input-id="admin_currency"
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_currency">
              {{ errors.admin_currency[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="$t('admin.settings.order_emails')"
            label-for="order_emails"
          >
            <b-form-input
              id="order_emails"
              v-model="data.order_emails"
              :state="errors && errors.order_emails ? false : null"
            />
            <b-form-invalid-feedback v-if="errors && errors.order_emails">
              {{ errors.order_emails[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      v-model="modalShowCache"
      centered
      size="lg"
      title="Clear cache tool"
      tabindex="0"
      @keyup.enter="clearCache"
    >
      <b-alert
        show
        variant="info"
        class="p-2"
      >
        Новий кеш буде згенеровано при першому запиті сутності. <br>
        Термін життя кешу - 8 годин від моменту створення (виключення - глобальні дані, тут 20 год). <br>
        Після закінчення терміну дії він автоматично видаляється.
      </b-alert>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h5>Системна очистка</h5>
          <b-form-group
            label="Кеш events (фонові події)"
            label-for="ccp_events"
          >
            <b-form-checkbox
              id="ccp_events"
              v-model="clearCacheParams.events"
            />
          </b-form-group>
          <b-form-group
            label="Кеш view (бекенд шаблони. напр, email)"
            label-for="ccp_view"
          >
            <b-form-checkbox
              id="ccp_view"
              v-model="clearCacheParams.view"
            />
          </b-form-group>
          <b-form-group
            label="Кеш route (бекенд роутинг)"
            label-for="ccp_route"
          >
            <b-form-checkbox
              id="ccp_route"
              v-model="clearCacheParams.route"
            />
          </b-form-group>
          <b-form-group
            label="Кеш config (бекенд конфігурація. напр: ключі НП і тд)"
            label-for="ccp_config"
          >
            <b-form-checkbox
              id="ccp_config"
              v-model="clearCacheParams.config"
            />
          </b-form-group>
          <b-form-group
            label="Кеш compiled (бекенд компілятор)"
            label-for="ccp_compiled"
          >
            <b-form-checkbox
              id="ccp_compiled"
              v-model="clearCacheParams.compiled"
            />
          </b-form-group>
          <b-form-group
            label="Кеш gfd (Генерація статичних файлів на S3)"
            label-for="ccp_gfd"
          >
            <b-form-checkbox
              id="ccp_gfd"
              v-model="clearCacheParams.gfd"
            />
          </b-form-group>
          <b-form-group
            label="!!! УВЕСЬ кеш: фільтри, товари, сторінки... ВСЕ"
            label-for="ccp_cache"
          >
            <b-form-checkbox
              id="ccp_cache"
              v-model="clearCacheParams.cache"
            />
          </b-form-group>
          <b-alert
            :show="clearCacheParams.cache"
            variant="warning"
            class="p-1"
          >
            Цей пункт скидає велику к-ть кешу і генерація нового займе значну к-ть ресурсів. Не скидайте повністю кеш без необхідності.
          </b-alert>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <h5>Часткова очистка даних для клієнта</h5>
          <b-form-group
            label="Лише кеш товарів"
            label-for="ccp_simple_product"
          >
            <b-form-checkbox
              id="ccp_simple_product"
              v-model="clearCacheParams.simple"
              value="product"
            />
          </b-form-group>
          <b-form-group
            label="Лише кеш каталогів та категорій"
            label-for="ccp_simple_add"
          >
            <b-form-checkbox
              id="ccp_simple_add"
              v-model="clearCacheParams.simple"
              value="add"
            />
          </b-form-group>
          <b-form-group
            label="Лише кеш фільтрів"
            label-for="ccp_simple_filter"
          >
            <b-form-checkbox
              id="ccp_simple_filter"
              v-model="clearCacheParams.simple"
              value="filter"
            />
          </b-form-group>
          <b-form-group
            label="Лише кеш підрахунку товарів"
            label-for="ccp_simple_count"
          >
            <b-form-checkbox
              id="ccp_simple_count"
              v-model="clearCacheParams.simple"
              value="count"
            />
          </b-form-group>
          <b-form-group
            label="Лише кеш статичних сторінок"
            label-for="ccp_simple_page"
          >
            <b-form-checkbox
              id="ccp_simple_page"
              v-model="clearCacheParams.simple"
              value="page"
            />
          </b-form-group>
          <b-form-group
            label="Лише кеш розширень-каруселей"
            label-for="ccp_simple_carousel"
          >
            <b-form-checkbox
              id="ccp_simple_carousel"
              v-model="clearCacheParams.simple"
              value="carousel"
            />
          </b-form-group>
          <b-form-group
            label="Лише кеш глобальних даних (меню, методи оплати, методи доставки, налаштування)"
            label-for="ccp_simple_base"
          >
            <b-form-checkbox
              id="ccp_simple_base"
              v-model="clearCacheParams.simple"
              value="base"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="primary"
          block
          @click="clearCache"
        >
          CLEAR CACHE
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import StatusMixin from '@/mixins/StatusMixin'
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin, StatusMixin],
  data() {
    return {
      currencies: [],
      languages: [],
      data: null,
      language: null,
      disabledCache: false,
      modalShowCache: false,
      clearCacheParams: {
        events: false,
        view: false,
        route: false,
        config: false,
        compiled: false,
        cache: false,
        gfd: false,
        simple: [],
      },
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http('/api/currencies')
      .then(response => {
        this.currencies = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })

    await this.$http('/api/admin/settings', { params: { group: 'general' } }).then(response => {
      this.data = this.transformData(response.data)

      /* eslint-disable camelcase */
      this.language = response.data.admin_language
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/admin/settings/general', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')
          await localStorage.setItem('localization', JSON.stringify({
            admin_language: this.data.admin_language,
            language: this.data.language.value,
          }))
          await this.changeLanguage()
          if (this.language !== this.data.admin_language) {
            window.location.reload()
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    clearCache() {
      this.confirm(() => {
        this.disabledCache = true
        setTimeout(() => {
          this.disabledCache = false
        }, 6000)
        this.$http.post('/api/admin/cache', { params: this.clearCacheParams })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: 'Запустити скидання?',
        text: 'Скидання триває 1-2 хв у фоновому режимі (декілька разів нажимати не треба). Рекомендовано скидати кеш не частіше 1 разу на годину.',
      })
    },
    changeLanguage() {
      this.$i18n.locale = this.data.admin_language
      this.$i18n.fallbackLocale = this.data.language
    },
    transformData(item) {
      return {
        maintenance_mode: item.maintenance_mode,
        language: item.language,
        admin_language: item.admin_language,
        currency: item.currency,
        admin_currency: item.admin_currency,
        order_emails: item.order_emails,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
